.logo {
  width: 150px;
  height: 55px;
}

.active {
  background-color: #65c8d0;
}

.modal-bg {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
}
