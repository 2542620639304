.ql-editor.ql-blank::before {
  content: none;
}

.ql-editor.ql-blank::after {
  color: rgba(0, 0, 0, 0.6);
  content: attr(data-placeholder);
  display: block;
  font-style: italic;
  margin-top: -1.42em;
  min-height: 30em;
  pointer-events: none;
}

.ql-editor {
  max-width: 100%;
  word-wrap: break-word;
  overflow-wrap: break-word;
  box-sizing: border-box;
  height: 400px;
}

.editor-container {
  width: 850px;
}

.logo {
  height: fit-content;
  width: 160px;
}

.phone-input {
  --react-international-phone-height: 40px;
  --react-international-phone-background-color: white;
  --react-international-phone-text-color: #222;
  --react-international-phone-font-size: 15px;
  --react-international-phone-border-radius: 4px;
  --react-international-phone-border-color: gainsboro;
  --react-international-phone-disabled-background-color: whitesmoke;
  --react-international-phone-disabled-text-color: #666;
}
