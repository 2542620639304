.date-pointer {
  cursor: text;
}

:root {
  --focus-color: #ffffff;
  --border-color: #ffffff;
  --hover-border-color: #ac0b0b;
  --input-background: #ffffff;
  --text-color: #4a6365;
  --selected-background: #eeeeee;
  --focus-background: #f0f0f0;
}
